import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';

import App from "./App";
import "./styles/app.less";

const GCID = "54510890763-1t27ss88njg5eb676b5rjiumn6o0ijc1.apps.googleusercontent.com";

const appRoot = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

appRoot.render(
    <GoogleOAuthProvider clientId={GCID}>
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>
    </GoogleOAuthProvider>
);